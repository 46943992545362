import React from "react";
import { graphql } from "gatsby";
import {
  Box,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Link,
  Paper,
} from "@mui/material";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const Messages = ({ data }) => {
  return (
    <>
      <Layout
        title="Messages"
        pathname="/messages"
        image={data.allMessagesJson.edges[0].node.imageURL.gatsbyImageData}
      >
        <Container maxWidth="lg" sx={{ mb: 3 }}>
          <Grid>
            <Grid item xs={12}>
              <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
                <ImageList gap={32} cols={2}>
                  {getImages(data)}
                </ImageList>
              </Box>
              <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
                <ImageList gap={32} cols={1}>
                  {getImages(data)}
                </ImageList>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  );
};

function getImages(data) {
  const imageListArray = [];
  data.allMessagesJson.edges.forEach((item) =>
    imageListArray.push(
      <Link
        href={item.node.path}
        key={item.node.id}
        underline="none"
      >
        <Paper elevation={3} sx={{ mb: 1 }}>
          <ImageListItem>
            <GatsbyImage
              image={getImage(item.node.imageURL)}
              alt={item.node.altText}
            />
            <ImageListItemBar
              title={item.node.title}
              position="below"
              sx={{ px: 2, pt: 0.5 }}
            />
          </ImageListItem>
        </Paper>
      </Link>
    )
  );

  return imageListArray;
}

export const query = graphql`
  {
    allMessagesJson(sort: { fields: createdDate, order: DESC }) {
      edges {
        node {
          altText
          featured
          id
          path
          tags
          title
          imageURL {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
export default Messages;
